@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    a {
        @apply text-ashblue-darker hover:underline focus:underline;
    }
}

@layer components {
    .container {
        @apply px-2 sm:px-4 mx-auto w-full max-w-5xl;
    }

    .skip-link:not(:focus) {
        @apply sr-only;
    }

    :root {
        --plyr-color-main: #96b0ad;
    }
}

@layer utilities {
    .flow {
        @apply py-8;
    }

    @variants responsive {
        .full-bleed {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
          }
        
          .not-full-bleed {
            width: auto;
            position: static;
            left: 0;
            right: 0;
            margin-left: 0;
            margin-right: 0;
          }
    }
}